import { Doc } from "src/app/models/doc.model";

export const DOCS_DATA: Array<Doc> = [
   {
      name: '2017',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2017',
            path: './assets/docs/EEFF_SFP_Porvenir_201703.pdf'
         },
         {
            name: 'Abril 2017',
            path: './assets/docs/EEFF_SFP_Porvenir_201704.pdf'
         },
         {
            name: 'Junio 2017',
            path: './assets/docs/EEFF_SFP_Porvenir_201706.pdf'
         },
         {
            name: 'Septiembre 2017',
            path: './assets/docs/EEFF_SFP_Porvenir_201709.pdf'
         },
         {
            name: 'Diciembre 2017',
            path: './assets/docs/EEFF_SFP_Porvenir_201712.pdf'
         },
      ]
   },
   {
      name: '2018',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2018',
            path: './assets/docs/EEFF_SFP_Porvenir_201803.pdf'
         },
         {
            name: 'Junio 2018',
            path: './assets/docs/EEFF_SFP_Porvenir_201806.pdf'
         },
         {
            name: 'Septiembre 2018',
            path: './assets/docs/EEFF_SFP_Porvenir_201809.pdf'
         },
         {
            name: 'Diciembre 2018',
            path: './assets/docs/EEFF_SFP_Porvenir_201812.pdf'
         },
      ]
   },
   {
      name: '2019',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2019',
            path: './assets/docs/EEFF_SFP_Porvenir_201903.pdf'
         },
         {
            name: 'Junio 2019',
            path: './assets/docs/EEFF_SFP_Porvenir_201906.pdf'
         },
         {
            name: 'Septiembre 2019',
            path: './assets/docs/EEFF_SFP_Porvenir_201909.pdf'
         },
         {
            name: 'Diciembre 2019',
            path: './assets/docs/EEFF_SFP_Porvenir_201912.pdf'
         },
      ]
   },
   {
      name: '2020',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2020',
            path: './assets/docs/EEFF_SFP_Porvenir_202003.pdf'
         },
         {
            name: 'Junio 2020',
            path: './assets/docs/EEFF_SFP_Porvenir_202006.pdf'
         },
         {
            name: 'Septiembre 2020',
            path: './assets/docs/EEFF_SFP_Porvenir_202009.pdf'
         },
         {
            name: 'Diciembre 2020',
            path: './assets/docs/EEFF_SFP_Porvenir_202012.pdf'
         },
      ]
   },
   {
      name: '2021',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2021',
            path: './assets/docs/EEFF_SFP_Porvenir_202103.pdf'
         },
         {
            name: 'Junio 2021',
            path: './assets/docs/EEFF_SFP_Porvenir_202106.pdf'
         },
         {
            name: 'Septiembre 2021',
            path: './assets/docs/EEFF_SFP_Porvenir_202109.pdf'
         },
         {
            name: 'Diciembre 2021',
            path: './assets/docs/EEFF_SFP_Porvenir_202112.pdf'
         },
      ]
   },
   {
      name: '2022',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2022',
            path: './assets/docs/EEFF_SFP_Porvenir_202203.pdf'
         },
         {
            name: 'Junio 2022',
            path: './assets/docs/EEFF_SFP_Porvenir_202206.pdf'
         },
         {
            name: 'Septiembre 2022',
            path: './assets/docs/EEFF_SFP_Porvenir_202209.pdf'
         },
         {
            name: 'Diciembre 2022',
            path: './assets/docs/EEFF_SFP_Porvenir_202212.pdf'
         },
      ]
   },
   {
      name: '2023',
      isOpen: false,
      children: [
         {
            name: 'Marzo 2023',
            path: './assets/docs/EEFF_SFP_Porvenir_202303.pdf'
         },
         {
            name: 'Junio 2023',
            path: './assets/docs/EEFF_SFP_Porvenir_202306.pdf'
         },
         {
            name: 'Septiembre 2023',
            path: './assets/docs/EEFF_SFP_Porvenir_202309.pdf'
         },
         {
            name: 'Diciembre 2023',
            path: './assets/docs/EEFF_SFP_Porvenir_202312.pdf'
         },
      ]
   },
   {
      name: '2024',
      children: [
         {
            name: 'Marzo 2024',
            path: './assets/docs/EEFF_SFP_Porvenir_202403.pdf'
         },
         {
            name: 'Junio 2024',
            path: './assets/docs/EEFF_SFP_Porvenir_202406.pdf'
         },
         {
            name: 'Septiembre 2024',
            path: './assets/docs/EEFF_SFP_Porvenir_202409.pdf'
         },
      ]
   },
];

export const REGULATION_DATA: Doc[] = [
   {
      name: 'Buró de Entidades Financieras',
      path: '/regulations/financial-institutions-bureau'
   },
   {
      name: 'CONDUSEF',
      path: '/regulations/condusef'
   },
];

export const LEGAL_DATA: Doc[] = [
   {
      name: 'Aviso de privacidad',
      path: '/terms/privacy-notice'
   },
   {
      name: 'Costo Anual Total (CAT)',
      path: '/cat'
   },
   {
      name: 'Costos y Comisiones',
      path: '/costs-and-commissions'
   }
];